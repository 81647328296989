<template>
  <div class="list">
    <div class="listBanner">
      <img class="img_c1" src="../assets/img/banner_img5.jpg" alt="">
    </div>
    <div class="listMain wrap">
      <el-row :gutter="30">
        <el-col :span="5">
          <div class="listSlid">
            <div class="listSlidHd">
              <!-- <h3>新闻中心</h3>
              <p>NEWS</p> -->
              <h3>{{slideHd.title}}</h3>
              <p>{{slideHd.sub}}</p>
            </div>
            <div class="listSlidList">
              <!-- <div class="listSlidListInner on">
                <a href="/list">公司要闻</a>
                <span></span>
              </div>
              <div class="listSlidListInner">
                <a href="/list?t=1">项目动态</a>
                <span></span>
              </div> -->
              <div class="listSlidListInner" :class="{on : active == index}" v-for="(item, index) in slideList" :key="index">
                <a :href="`${apiName}specail?t=${index}`">{{item.name}}</a>
                <span></span>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="19">
          <div class="listWrap">
            <div class="listHd clearfloat">
              <div class="fl listHdName">专题专栏</div>
              <div class="fr listHdGrop"><a :href="`${apiName}`">首页</a> · <a :href="`${apiName}specail`">专题专栏</a> · <span class="txt">{{hdName}}</span> </div>
            </div>
            <div class="listContainer">
              <ul
                class="listList"
                v-loading="loading"
                element-loading-text="Loading"
                element-loading-spinner="el-icon-loading">
                <li v-for="(item, index) in listData" :key="index">
                  <a v-if="!item.is_link || item.is_link === 0" class="clearfloat" :href="`${apiName}detail?id=${item.id}`" target="_blank">
                    <div class="time fr">
                      <h3>{{dayjs(item.create_time).format('MM-DD')}}</h3>
                      <p>{{dayjs(item.create_time).format('YYYY')}}</p>
                    </div>
                    <div class="inner single-line">
                      {{item.title}}
                    </div>
                  </a>
                  <a class="clearfloat"  v-if="item.is_link === 1" target="_blank" :href="item.link_url">
                    <div class="time fr">
                      <h3>{{dayjs(item.create_time).format('MM-DD')}}</h3>
                      <p>{{dayjs(item.create_time).format('YYYY')}}</p>
                    </div>
                    <div class="inner single-line">
                      {{item.title}}
                    </div>
                  </a>
                </li>
              </ul>
              <div class="listPage">
                <el-pagination
                  background
                  layout="prev, pager, next"
                  :current-page="pageNum"
                  :page-size="pageSize"
                  :total="total"
                  @current-change="currentChange">
                </el-pagination>
              </div>
              
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
export default {
  name: 'list',
  data(){
    return {
      loading: false,
      listId: null,
      hdTitle: '',
      hdName: '',
      pageCont: '',
      active: 0,
      slideHd: {
        title: '专题专栏',
        sub: 'SPECIAL COLUMN',
      },
      slideList:[
        {
          title: '基本情况',
          id: '23701'
        },
        {
          title: '党建动态',
          id: '23802'
        }
      ],
      pageSize: 10,
      pageNum: 1,
      total: 0,
      listData: []
    }
  },
  mounted(){
    var _this = this
    if(_this.$route.query.t){
      _this.active = _this.$route.query.t
      // _this.navNameShow = true
      // _this.name1 = _this.$route.query.navName;
      // _this.navNameUrl = _this.navNameList[_this.$route.query.navName]
    }else{
      _this.active = 0
    }
    _this.getSideNav()
  },
  methods: {
    currentChange(e){
      var _this = this;
      _this.pageNum = e
      _this.getList()
    },
    // 获取二级栏目
    async getSideNav() {
      var _this = this;
      
      var { data } = await _this.$http.post(_this.apiUrl + '/group/getGrouplistByNavId', {id: "138"});
      _this.slideList = data.data
      _this.hdTitle = _this.slideList[_this.active].name
      _this.hdName = _this.slideList[_this.active].name
      _this.listId = _this.slideList[_this.active].id
      _this.getList()
    },
    // 查列表
    async getList() {
      var _this = this;
      
      _this.loading = true
      var { data } = await _this.$http.post(_this.apiUrl + '/news/getNewsList', {group_id: _this.listId, page: _this.pageNum, pageSize: _this.pageSize});
      console.log('data :>> ', data);
      _this.listData = data.data.list
      _this.total = data.data.total
      _this.loading = false
    },
  }
}
</script>